<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['excessShine'])">
      <single-answer-question
        id="excess-shine-question"
        title="label.excessShine"
        :value="excessShine"
        :options="$options.yesNoResponseOptions"
        dysfunction="skin-oiliness"
        yes-no
        @input="onFieldChange('excessShine', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap
      class="q-mt24 q-mt32-md"
      :error="getFieldsError(['onlyGreasyInSummer'])"
    >
      <single-answer-question
        id="only-greasy-in-summer"
        title="label.onlyGreasyInSummer"
        :value="onlyGreasyInSummer"
        :options="$options.yesNoResponseOptions"
        yes-no
        dysfunction="skin-oiliness"
        @input="onFieldChange('onlyGreasyInSummer', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';

export default {
  name: 'EnvironmentTemplate',
  components: { SingleAnswerQuestion, QuestionWithErrorWrap },
  mixins: [stepTemplateMixin],
  yesNoResponseOptions: YES_NO_RESPONSE_OPTIONS,
  props: {
    excessShine: {
      type: String,
      default: ''
    },
    onlyGreasyInSummer: {
      type: String,
      default: ''
    }
  }
};
</script>
